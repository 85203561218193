

import {defineComponent, ref, watch, computed, onMounted} from "vue";
import {l} from '@/core/helpers/lang';
import store from "@/store";
import {Cart} from "@/store/modules/AuthModule";
import NumberFormat from "@/core/functions/NumberFormat";
import cardProductRemove from '@/components/backoffice/cart/cartProductRemove.vue';
import _ from "lodash"
import CartAddRemoveButton from "@/components/backoffice/cart/cartAddRemoveButton.vue";

export default defineComponent({
  name: "Carts",
  components: {
    CartAddRemoveButton,
    cardProductRemove
  },
  data() {
    return {
      data: [],
    }
  },
  setup() {
    const elm = document.querySelector<HTMLDivElement>("#kt_quick_cart");
    const elm2 = document.querySelector<HTMLDivElement>(".offcanvas-overlay")
    if (elm) {
      elm.classList.remove('offcanvas-on');
    }
    if (elm2) {
      elm2.classList.add('d-none');
    }
    const carts = ref<Cart>({
      items: [],
      totalPriceWithoutTax: 0,
      totalTax: 0,
      totalDiscount: 0,
      totalHamPrice: 0,
      totalPrice: 0,
      totalQty: 0,
      userGeneralDiscount: 0
    });
    const items = ref([]);

    const computedCarts= computed<Cart>(() => store.getters.currentUser.carts);

    watch(computedCarts, (newValue, oldValue) => {
      carts.value = newValue;
      items.value = newValue.items;
    })

    onMounted(()=>{
      carts.value=computedCarts.value ?? carts.value;
      items.value = computedCarts.value?.items ?? [];
    });

    return {
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      carts,
      items,
      l,
      NumberFormat,
      _
    }
  }
});
