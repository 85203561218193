<template>
  <a href="#" class="font-size-sm ">
    <i class="fas fa-trash text-danger" @click="removeCart(this.productID)"></i>
  </a>
</template>

<script>

import get from "@/core/functions/get" ;
import {api_url} from "@/ayar";

import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnums";

export default {
  name: "cartProductRemove",
  props:{
    productID : {
      type :[Number, String]
    }
  },
  methods:{
    removeCart(productID){
      console.log(productID);
      get(api_url + `removecart/` + productID).then(res => {
        store.commit(Mutations.SET_CART,res.data.carts);
      });
    }
  },
}
</script>

